<div class="body">


    <div id="wrapper">
        <div id="main">
            <div class="inner">
                <section id="home-section" class="active">
                    <div id="container10" class="container columns full screen">
                        <div class="wrapper">
                            <div class="inner">
                                <div>
                                    <div id="image14" class="image"><span class="frame"><img
                                                src="/assets/images/icon-512x512.png" alt=""></span></div>
                                    <h1 id="text48" class="style5">Facciobene</h1>
                                    <p id="text46" class="style1">

                                        <!--  With its user-friendly interface and powerful features, facciobene makes
                                        it
                                        easy to create to-do lists, set
                                        reminders, and track your progress.  -->

                                        Whether you're a busy professional, a
                                        student, or just looking for a better way to
                                        stay on top of your to-dos, facciobene has you covered. Try it out today and
                                        take
                                        control of your to-do list like never
                                        before!

                                    </p>
                                    <ul id="buttons04" class="buttons">
                                        <li>
                                            <a href="https://facciobene.xyz/login" class="button n01">
                                                <span class="label">Sign up now</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <div id="image11" class="image full"><span class="frame"><img
                                                src="assets/images/app.png" alt=""></span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="container07" data-scroll-id="explore" data-scroll-behavior="default" data-scroll-offset="0"
                        class="container default full screen">
                        <div class="wrapper">
                            <div class="inner">
                                <h2 id="text11">Features</h2>
                                <p id="text23" class="style1">Some of the standout features of facciobene include</p>
                            </div>
                        </div>
                    </div>
                    <div id="container01" class="container columns full screen">
                        <div class="wrapper">
                            <div class="inner">
                                <div>
                                    <!-- <div id="image03" class="style1 image"><span class="frame"><img
                                                src="./Cartel_files/image03.svg" alt=""></span></div> -->
                                    <h2 id="text01" class="style3">Reminders</h2>
                                    <p id="text02" class="style2">Set custom reminders for your tasks so you never
                                        forget a deadline or important event.</p>
                                    <!-- <ul id="buttons05" class="style1 buttons">
                                        <li><a href="https://domain.ext/path" class="button n01"><svg>
                                                    <use xlink:href="#icon-a37"></use>
                                                </svg><span class="label">Get</span></a></li>
                                    </ul> -->
                                </div>
                                <div>
                                    <!-- <div id="image04" class="style1 image"><span class="frame"><img
                                                src="./Cartel_files/image04.svg" alt=""></span></div> -->
                                    <h2 id="text03" class="style3">Week and Day View</h2>
                                    <p id="text04" class="style2">View your tasks for the week or day at a glance, and
                                        quickly see what's coming up next.</p>
                                    <!-- <ul id="buttons07" class="style1 buttons">
                                        <li><a href="https://domain.ext/path" class="button n01"><svg>
                                                    <use xlink:href="#icon-a37"></use>
                                                </svg><span class="label">Get</span></a></li>
                                    </ul> -->
                                </div>
                                <div>
                                    <!-- <div id="image05" class="style1 image"><span class="frame"><img
                                                src="./Cartel_files/image05.svg" alt=""></span></div> -->
                                    <h2 id="text05" class="style3">Customizable Lists</h2>
                                    <p id="text06" class="style2">Create as many lists as you need, and customize each
                                        one with its own set of tasks and due dates. You can also create sub lists and
                                        allow collaborators to view all children lists.</p>
                                    <!-- <ul id="buttons02" class="style1 buttons">
                                        <li><a href="https://domain.ext/path" class="button n01"><svg>
                                                    <use xlink:href="#icon-a37"></use>
                                                </svg><span class="label">Get</span></a></li>
                                    </ul> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="container03" class="container columns full screen">
                        <div class="wrapper">
                            <div class="inner">
                                <div>
                                    <!-- <div id="image08" class="style1 image"><span class="frame"><img
                                                src="./Cartel_files/image08.svg" alt=""></span></div> -->

                                    <h2 id="text29" class="style3">Repeat tasks</h2>
                                    <p id="text30" class="style2">

                                        This
                                        feature allows you to define a repeat
                                        schedule for each task, so that the system can automatically create a new
                                        instance of the task when the previous one is
                                        marked as complete. This is especially useful for tasks that need to be
                                        completed on a regular basis, such as taking out
                                        the trash or paying bills.

                                    </p>


                                    <p class="style2">
                                        With the repeat functionality, you can set tasks to repeat daily, weekly,
                                        monthly, or even yearly. You can also
                                        customize the repeat schedule to fit your specific needs, so you can have tasks
                                        repeat on certain days of the week or on
                                        specific dates.
                                    </p>

                                    <p class="style2">
                                        Whether you need to remember to water your plants every Monday or pay your rent
                                        on the first of the month, the repeat
                                        functionality in facciobene has you covered.
                                    </p>



                                    <!-- <ul id="buttons08" class="style1 buttons">
                                        <li><a href="https://domain.ext/path" class="button n01"><svg>
                                                    <use xlink:href="#icon-a37"></use>
                                                </svg><span class="label">Get</span></a></li>
                                    </ul> -->
                                </div>
                                <div>
                                    <!-- <div id="image09" class="style1 image"><span class="frame"><img
                                                src="./Cartel_files/image09.svg" alt=""></span></div> -->
                                    <h2 id="text32" class="style3">Collaboration</h2>
                                    <p id="text33" class="style2">Share lists with friends, family, or coworkers and
                                        work together to get things done. Also track progress of your peers with the
                                        activity view. Facciobene will notify you about tasks being done, so you keep up
                                        to date about the status of your projects.</p>
                                </div>
                                <div>
                                    <h2 id="text37" class="style3">Available on All Devices</h2>
                                    <p id="text39" class="style2">
                                        Use facciobene on your phone, tablet, or computer, and
                                        keep your tasks synced across all your devices.
                                    </p>

                                    <p class="style2">
                                        Facciobene is available as a Web apps which is easier to install and access, as
                                        you don't need to go through an app store or install any
                                        additional software. You can simply open the app in your web browser and start
                                        using it. You will always be up to date.
                                    </p>

                                    <p class="style2">
                                        To install the app, follow these steps:

                                    <p class="style2">
                                        1. Open your web browser and go to the website where you can access the
                                        Facciobene app.

                                        2. Look for the option to "install" or "add to home screen" in your browser's
                                        menu or settings.

                                        3. Follow the prompts to install the app.

                                        4. Once the app is installed, you should see an icon for it on your home
                                        screen or app menu.
                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="container03" class="container columns full screen">
                        <div class="wrapper">
                            <div class="inner">

                                <div>
                                    <h2 id="text37" class="style3">Open to integrate with other services.</h2>

                                    <p id="text39" class="style2">
                                        One of the great things about facciobene is that it comes with open APIs,
                                        which allow you to easily integrate it with
                                        other software tools you may be using. This can be especially useful if you're
                                        working on a team and need to integrate
                                        your to-do list with other project management or productivity tools.
                                    </p>

                                    <p id="text39" class="style2">
                                        With our open APIs, you can easily connect facciobene to other apps and
                                        software, and sync your tasks and data between
                                        them. This can help you save time and streamline your workflow, as you won't
                                        need to switch between multiple apps to get
                                        your work done.
                                    </p>
                                    <p id="text39" class="style2">
                                        If you're interested in using facciobene and want to take advantage of its
                                        open APIs, be sure to check out our
                                        developer documentation for more information.
                                    </p>

                                </div>



                            </div>
                        </div>
                    </div>


                    <!-- <div id="container02" class="container columns full screen">
                        <div class="wrapper">
                            <div class="inner">
                                <div>
                                    <h2 id="text07" class="style5">Get notify</h2>
                                    <p id="text08" class="style1">These are just a few of the many features that make
                                        facciobene the best choice for staying organized and on top of
                                        your tasks. Try it out today and see the difference it can make in your life!
                                    </p>
                                    <form id="form01" method="post" class="style1">
                                        <div class="inner">
                                            <div class="field"><input type="email" name="email" id="form01-email"
                                                    placeholder="Email" maxlength="128" required=""></div>
                                            <div class="field" style="display: none;"><input type="text" name="fax"
                                                    id="form01-fax" placeholder="Fax" maxlength="128" disabled=""></div>
                                            <div class="actions"><button type="submit"><svg>
                                                        <use xlink:href="#icon-a31"></use>
                                                    </svg><span class="label">Join</span></button></div>
                                        </div><input type="hidden" name="id" value="form01">
                                    </form>
                                </div>
                                <div>
                                    <div id="image06" class="image full"><span class="frame"><img
                                                src="./Cartel_files/image06.svg" alt=""></span></div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <!-- <div id="container20" class="container columns full screen">
                        <div class="wrapper">
                            <div class="inner">
                                <div>
                                    <p id="text16">© 2018 All rights reserved. Carlos Gomes</p>
                                </div>
                                <div>
                                    <div id="image01" class="image"><span class="frame"><img
                                                src="./Cartel_files/icon-512x512.png" alt=""></span></div>
                                </div>
                            </div>
                        </div>
                    </div> -->


                </section>
                <!-- <section id="thank-you-section" class="inactive" style="display: none;">
                    <div id="container06" class="container default full screen">
                        <div class="wrapper">
                            <div class="inner">
                                <h2 id="text12">Check your email!</h2>
                                <p id="text40" class="style1">Duis aute irure dolor in reprehenderit in voluptate velit
                                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat.</p>
                            </div>
                        </div>
                    </div>
                </section> -->
            </div>
        </div>
        <div class="footer-basic">
            <footer>
                <!-- <div class="social">
                    <a href="#"><i class="icon ion-social-instagram"></i></a>
                    <a href="#"><i class="icon ion-social-snapchat"></i></a>
                    <a href="#"><i class="icon ion-social-twitter"></i></a>
                    <a href="#"><i class="icon ion-social-facebook"></i></a>
                </div> -->
                <div class="footer-links-inline">
                    <a href="https://stats.uptimerobot.com/43xPOhzOrx" target="_blank">Status</a>

                    <a href="https://facciobene.xyz/public/lists/b8f38ff8-1ef2-4625-bdcf-12c26c628481"
                        target="_blank">Roadmap</a>

                    <a href="#" onclick="alert('coming soon');" target="_blank">API</a>

                </div>
                <p class="copyright">© 2018 - 2023 Carlos Gomes </p>
            </footer>
        </div>
    </div>
</div>
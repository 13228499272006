import { Component, Inject, Optional } from '@angular/core';

import { AngularFireAnalytics } from '@angular/fire/compat/analytics'
import { IEnvironment } from './interfaces/environment.interface';
// import { SwUpdate } from '@angular/service-worker';
import { Subject } from 'rxjs';
import { FACCIOBENE_ENVIRONMENT } from './injectors.constant';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  // private _unsubscribe$: Subject<void> = new Subject<void>();
  deferredPrompt = null;
  usingAsInstalledApp = false;

  constructor(
    // private updates: SwUpdate,
    @Optional() @Inject(FACCIOBENE_ENVIRONMENT) public environment: IEnvironment,
    @Optional() private angularFireAnalytics: AngularFireAnalytics
  ) {

    if (this.environment.envName === 'local_dev') {
      this.angularFireAnalytics?.setAnalyticsCollectionEnabled(false);
    }

    // window.addEventListener('beforeinstallprompt', (e: any) => {
    //   try {
    //     e.preventDefault();

    //     // console.log('beforeinstallprompt: ' );
    //     // console.log(e);
    //     // e.userChoice.then((choiceResult) => {
    //     //   this.angularFireAnalytics?.logEvent(
    //     //     `before_install_web_app_prompt`,
    //     //     {
    //     //       outcome: choiceResult.outcome,
    //     //     }
    //     //   );
    //     // });
    //   } catch (error) {
    //     console.error(error);
    //   }
    // });
  }

  public ngOnInit(): void {
    // if (this.updates.isEnabled) {
    //   this.updates.checkForUpdate();
    // }
    // this.updates.activated.subscribe((event) => {
    //   console.log('old version was', event.previous);
    //   console.log('new version is', event.current);
    //   console.log('activated', event);
    // });
    // this.updates.unrecoverable.subscribe((event) => {
    //   console.error('unrecoverable', event);
    //   console.error(event.reason);
    // });
    // this.updates.available.subscribe((event) => {
    //   console.log('available', event);
    //   console.log('old version was', event.current);
    //   console.log('new version is', event.available);
    //   this.angularFireAnalytics?.logEvent(`update_available`);
    // });
  }

  public ngOnDestroy(): void {
    // this._unsubscribe$.next();
    // this._unsubscribe$.complete();
  }
}

export const environment = {
  production: true,
  envName: 'prod',
  baseUrl: 'https://api.facciobene.xyz',
  apiV1: 'api/v1/',
  uiDomain: 'https://facciobene.xyz',

  firebase: {
    apiKey: 'AIzaSyCNa82_bUqEWQ9hG4zIJi-1owjmzs8ceUs',
    authDomain: 'facciobene-4e17d.firebaseapp.com',
    projectId: 'facciobene-4e17d',
    storageBucket: 'facciobene-4e17d.appspot.com',
    messagingSenderId: '642716938990',
    appId: '1:642716938990:web:7e04a025454512b397f9ff',
    measurementId: 'G-YB2749CQ2Z'
  },
  defaultLanguage: 'en',
  languages: ['en']
};

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { versions } from '@shared/versions';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';

if (environment.production) {
  enableProdMode();

  Sentry.init({
    dsn: 'https://2b294354f2954354a4318550489a5170@o516287.ingest.sentry.io/6469465',
    integrations: [

      new Integrations.BrowserTracing({
        tracingOrigins: ['localhost:4200', 'https://facciobene.xyz', 'https://facciobene-4e17d.web.app'],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],

    tracesSampleRate: 0.01,
  });
}


platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((success) => {
    console.log(
      `Welcome to Facciobene!\nDoes this page need fixes or improvements? Send up some feedback!`
    );
    console.log(`Bootstrap success: ${versions.hash}, ${versions.date}`);
  })
  .catch((err) => console.error(err));
